import PaginationType from "@/core/types/misc/Pagination";
import { FilterData } from "@/core/types/misc/FilterData";
import { defineStore } from "pinia";
import {
  ApplicationItem,
  MedicalServiceApplication,
} from "@/core/types/gws-global-feature/cpmi/MedicalServiceApplication";
import { AxiosResponse } from "axios";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import ApiService from "@/core/services/ApiService";
import { ListData } from "@/core/types/gws-master/master/medical-services/informed-consent/ListData";
import { ListData as DoctorItem } from "@/core/types/gws-medical/doctor-management/ListItem";
import { ScreeningItem } from "@/core/types/gws-medical/digital-data/ScreeningItem";
import { useAuthenticationStore } from "../../application/useAuthenticationStore";
import { Option } from "@/core/types/misc/Option";

const authStore = useAuthenticationStore();
export const useMedicalDigitalDataListStore = defineStore({
  id: "medicalDigitalDataListStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFilterData: {
        page: 1,
        limit: 10,
        dir: "DESC",
        sort: "created_at",
        search: "",
      } as FilterData,
      statePagination: new PaginationType(),
      stateDisplayData: [] as ApplicationItem[],
      stateInformedConsent: [] as ScreeningItem[],
      stateDoctorOption: [] as Option[],
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    displayData(state) {
      return state.stateDisplayData;
    },
    informedConsent(state) {
      return state.stateInformedConsent;
    },
    doctors(state) {
      return state.stateDoctorOption;
    },
  },
  actions: {
    async getData(isPageRefresh = true) {
      this.stateIsLoading = true;
      this.stateDisplayData = [];
      const params = {
        page: this.stateFilterData.page.toString() || "",
        limit: this.stateFilterData.limit.toString() || "",
        dir: this.stateFilterData.dir || "",
        sort: this.stateFilterData.sort || "",
        status: "disetujui",
      };
      if (this.stateFilterData.search) {
        params["search"] = this.stateFilterData.search;
      }
      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<MedicalServiceApplication>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `medical-service-application?${new URLSearchParams(
              params
            ).toString()}`
          );
        this.stateDisplayData = response.data.data.medical_service_application;
        this.setTotalPage(response.data.meta.pagination.count);
      } catch (error: any) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
    async getLookupInformedConsent() {
      this.stateInformedConsent = [];
      const params = {
        page: "1",
        limit: "99",
        dir: "DESC",
        sort: "created_at",
      };
      if (this.stateFilterData.search) {
        params["search"] = this.stateFilterData.search;
      }
      try {
        const response: AxiosResponse<ApiResponse<ListData[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `medical/consents?${new URLSearchParams(params).toString()}`
          );
        const consents = response.data.data;
        consents.forEach((el) => {
          this.stateInformedConsent.push({
            type: el.type,
            question: el.name,
            answer: "",
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupDoctor(isPageRefresh = true) {
      this.stateDoctorOption = [];
      this.stateIsLoading = true;
      authStore.getUserInfo();
      const params = {
        page: "1",
        limit: "99",
        dir: "DESC",
        sort: "created_at",
      };
      if (this.stateFilterData.search) {
        params["search"] = this.stateFilterData.search;
      }
      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<DoctorItem[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `medical/facilities/${
              authStore.user.org_id || ""
            }/doctor?${new URLSearchParams(params).toString()}`
          );
        const doctors = response.data.data;
        doctors.forEach((el) => {
          this.stateDoctorOption.push({
            label: el.name,
            value: el.id,
          });
        });
      } catch (error) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
    async setPage(newPage: number) {
      this.statePagination.handleCurrentPageChange(newPage);
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData();
    },
    async setPageSize(size: number) {
      this.stateFilterData.limit = size;
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData(true);
    },
    setTotalPage(totalPage: number) {
      this.statePagination.totalPage = totalPage;
      this.statePagination.getPaginationStartIndex();
      this.statePagination.getPaginationLastIndex();
    },
  },
});
