import ApiService from "@/core/services/ApiService";
import {
  FormData,
  QuestionAnswer,
  Result,
  Screening,
} from "@/core/types/gws-medical/digital-data/FormData";
import { ApiResponse, Meta } from "@/core/types/misc/ApiResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { useAuthenticationStore } from "../../application/useAuthenticationStore";
import { defineStore } from "pinia";
import { ResultDetail } from "@/core/types/gws-medical/digital-data/DigitalResult";

const authStore = useAuthenticationStore();
export const useMedicalDigitalDataFormStore = defineStore({
  id: "medicalDigitalDataFormStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFormData: {
        cpmi_id: "",
        doctor_id: "",
        cpmi_name: "",
        cpmi_package: "",
      },
      stateFormDataScreening: {} as Screening,
      stateFormDataResult: {} as Result,
      stateFormDataScreeningConsent: [] as QuestionAnswer[],
      stateError: false,
      stateErrors: [] as string[],
      stateErrorMsg: "",
      stateUpdateId: "",
      stateApplicationDetailId: "",
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    formData(state) {
      return state.stateFormData;
    },
    error(state) {
      return state.stateError;
    },
    errors(state) {
      return state.stateErrors;
    },
    errorMessage(state) {
      return state.stateErrorMsg;
    },
  },
  actions: {
    async updateMedicalRequest(reject: boolean, approve: boolean) {
      this.stateIsLoading = true;
      try {
        const payload = {
          reject: reject,
          approve: approve,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/medical-service-application/${this.stateUpdateId}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        if (metaResp) {
          metaResp.errors[0].forEach((err) => {
            this.stateErrors.push(err.message);
          });
        } else {
          this.stateErrors.push(error);
        }
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async submitDigitalData() {
      this.stateIsLoading = true;
      this.stateFormDataScreening.question_answer =
        this.stateFormDataScreeningConsent;
      try {
        const payload = {
          medical_result: {
            screening: this.stateFormDataScreening,
            cpmi: {
              id: this.formData.cpmi_id,
            },
            result: this.stateFormDataResult,
          },
          facility_doctor_id: this.formData.doctor_id,
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/medical-service-application/${this.stateUpdateId}/medical_service_application_detail/${this.stateApplicationDetailId}/data-digital-issuer`,
          payload as AxiosRequestConfig
        );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        if (metaResp) {
          metaResp.errors[0].forEach((err) => {
            this.stateErrors.push(err.message);
          });
        } else {
          this.stateErrors.push(error);
        }
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
    },
    // setFormData(data: FormData) {
    //   this.stateFormData = {
    //     facility_doctor_id: data.facility_doctor_id,
    //   };
    // },
    resetFormData() {
      this.stateFormData = {
        cpmi_id: "",
        doctor_id: "",
        cpmi_name: "",
        cpmi_package: "",
      };
      this.stateFormDataScreening = {} as Screening;
      this.stateFormDataResult = {} as Result;
      this.stateFormDataScreeningConsent = [] as QuestionAnswer[];
    },
  },
});
